/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useDispatch } from 'react-redux';
import { toggleForm } from '../state/reducers/utils';
import Grid from './grid';
import { Typography } from '../constants/typography';
import PrimaryButton from './buttons/primary';
import ScrollButton from './buttons/scroll';
import { Constants } from '../constants';
import Button from './buttons/button';

export const IntroSlide = ({
  outlineText, fillText, textColor, strokeColor, bg, headerColor,
}) => (
  <div
    className={`
      ${bg}
      ${headerColor}
      ${Constants.panelBase}
      sm:responsive-h-screen
      tp:responsive-h-screen
      tl:responsive-h-screen
      flex items-center lg:items-end justify-center lg:justify-start
      lg:p-16
    `}
  >
    <h1 className={`${Typography.h1} ${textColor} max-w-[600px] text-center lg:text-left`}>
      <span className={`block ${strokeColor}`}>
        {outlineText}
      </span>
      {fillText}
    </h1>
    <ScrollButton colorClassName={`${bg === 'bg-tan' ? 'text-charcoal' : 'text-yellow'}`} scrollTo="faqs" />
  </div>
);

export const AccordionButton = ({
  className, onClick, isPlus, gtagId = null,
}) => {
  const width = 24;
  const height = 2;
  return (
    <Button id={gtagId != null ? `spotterla_faqs_${gtagId}` : ''} onClick={onClick} className={`${className} relative inline-flex items-center justify-center h-14 w-14 rounded-full transition-all duration-700 ease-out-expo border-[2px] border-black bg-transparent hover:bg-black group`}>
      <svg id={gtagId != null ? `spotterla_faqs_${gtagId}_svg` : ''} className={`transition-all duration-700 ease-out-expo text-black group-hover:text-white ${isPlus ? 'rotate-90' : 'rotate-0'}`} width={width} height={width} viewBox={`0 0 ${width} ${width}`}>
        <rect id={gtagId != null ? `spotterla_faqs_${gtagId}_recty` : ''} y="11" fill="currentColor" width={width} height={height} />
        <rect id={gtagId != null ? `spotterla_faqs_${gtagId}_rectx` : ''} x="13" fill="currentColor" width={width} height={height} className={`${isPlus ? 'opacity-100' : 'opacity-0'}`} transform="rotate(90 13 0)" />
      </svg>
    </Button>
  );
};

export const FAQItem = ({
  question, answer, index, gtagId = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editquestion, setEditquestion] = useState(null);
  const triggerAccordion = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    // setEditquestion(question.slice(3, -5)); // TODO Why that slice exist?
    setEditquestion(question);
  }, [question]);
  return (
    <div className="w-full transition-all duration-700 ease-out">
      <Grid gapConfig="gap-0" className="items-start pb-10 lg:pb-11 lg:px-16 transition-all duration-700">
        { index > 0 && <hr className="col-span-full pb-10 lg:pb-10 border-charcoal" /> }
        <Button onClick={triggerAccordion} className={`${Typography.h4} sm:pb-1 col-span-full text-left tl:col-span-full  lg:col-span-5 mb-2 lg:mb-0`}><p id={gtagId != null ? `spotterla_faqs_${gtagId}` : ''}>{editquestion}</p></Button>
        <div id={gtagId != null ? `spotterla_faqs_${gtagId}_div` : ''} className={`${isOpen ? 'h-auto' : 'h-0'} accordion-content col-span-full tl:col-span-full  lg:col-span-4 lg:col-start-8 transition-all duration-500 ease-out`}>
          <div id={gtagId != null ? `spotterla_faqs_${gtagId}_text` : ''} className={`${Typography.body1} text-charcoal mt-5 tl:mt-5 lg:mt-0 duration-500 ease-out-expo ${isOpen ? 'opacity-100 translate-y-0 transition-all' : 'opacity-0 translate-y-1 transition-none'}`} dangerouslySetInnerHTML={{ __html: answer }} />
        </div>
        <div className="col-span-4 tp:col-span-full tl:col-span-full lg:col-span-1 flex justify-end mt-8 tl:mt-8lg:mt-0">
          <AccordionButton onClick={triggerAccordion} isPlus={!isOpen} gtagId={gtagId} />
        </div>
      </Grid>
    </div>
  );
};

export const FAQsList = ({ faqs, gtags = null }) => (
  <div className="pt-10 lg:pt-32 mb-24 dark-header header-section" id="faqs">
    {
      faqs.map((item, index) => (
        <FAQItem
          key={`creators-faq-${index}`}
          question={item.question}
          answer={item.answer}
          index={index}
          gtagId={gtags != null ? gtags[index] : null}
        />
      ))
    }
  </div>
);

export const Asterisks = ({
  className = 'relative',
}) => (
  <div className={`mx-auto w-[90vw] h-[45vw] lg:w-[50vw] lg:h-[25vw] overflow-hidden ${className}`}>
    <StaticImage
      src="../../static/images/asterisk-faq.svg"
      alt="asterisk"
      style={{ position: 'absolute' }}
      className="top-0 left-1/2 transform -translate-x-1/2 w-full"
      loading="eager"
      objectFit="contain"
    />
    <StaticImage
      src="../../static/images/asterisk-faq.svg"
      alt="asterisk"
      style={{ position: 'absolute' }}
      className="top-[25%] lg:top-[20%] left-1/2 transform -translate-x-1/2 w-full"
      loading="eager"
      objectFit="contain"
    />
    <StaticImage
      src="../../static/images/asterisk-faq.svg"
      alt="asterisk"
      style={{ position: 'absolute' }}
      className="top-[50%] lg:top-[40%] left-1/2 transform -translate-x-1/2 w-full"
      loading="eager"
      objectFit="contain"
    />
  </div>
);

export const ContactUs = ({ type }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={`
        bg-yellow dark-header header-section
        ${Constants.panelBase}
        flex flex-col justify-center items-center
        px-8
        text-center
      `}
    >
      <h1 className={`${Typography.h1}`}>
        Contact us
      </h1>
      <h5 className={`${Typography.h5} text-charcoal py-8`}>
        Want to publish a Spotter feature?
      </h5>
      <PrimaryButton onClick={() => location.href = 'https://connect.spotter.la'} color="charcoal" hoverClassName="hover:border-charcoal hover:bg-yellow hover:text-charcoal">
        Introduce yourself
      </PrimaryButton>
    </div>
  );
};
