/* eslint-disable no-console */
import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../components/app-layout';
import { Typography } from '../../constants/typography';
import { ContactUs, FAQsList, IntroSlide } from '../../components/faq-assets';
import { Constants } from '../../constants';

const FAQSPage = ({ data }) => {
  const faq = data.wpFaq;
  const { title } = faq;
  const content = faq.faq;
  const faqs = content.faqs.faq;

  const gtagClickIds = [
    'determinedollaramount',
    'offerperdeal',
    'involvedinchannel',
    'ownchannel',
    'finalizedeal',
    'mcn',
    'whatspottercando',
    'impactfutureearnings',
  ];

  return (
    <Layout defaultHeaderColor="text-white">
      <SEO title={title} />
      <div className="w-full h-full" data-scroll-section>
        <IntroSlide
          outlineText={content.titleOutline}
          fillText={content.titleFilled}
          textColor="text-yellow"
          strokeColor={Typography.strokedYellow}
          bg="bg-charcoal"
          headerColor="light-header header-section"
        />
        <FAQsList faqs={faqs} gtags={gtagClickIds} />
        <ContactUs type={Constants.CREATOR} />
      </div>
    </Layout>
  );
};

export default FAQSPage;

export const faqsCreatorsData = graphql`
  query faqsCreatorsQuery {
    wpFaq(databaseId: {eq: 497}) {
      slug
      title
      uri
      faq {
        titleOutline
        titleFilled
        faqs {
          faq {
            question
            answer
          }
        }
      }
    }
  }
`;
